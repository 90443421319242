import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { AnalysisModel } from "../data/analysis.model";

export const GetAnalysisListUsecase: () => Promise<
  AnalysisModel[]
> = async () => {
  try {
    let response = await client.get(
      ApiConst.API_ANALYSIS + "?status=published&limit=1000"
    );
    let data: AnalysisModel[] = response.data.data.data.map((item: any) => {
      return {
        id: item.id.toString(),
        buy_price: item.buy_price,
        code: item.code,
        image_url: item.image_url,
        margin_of_safety: item.margin_of_safety,
        current_price: item.current_price,
        valuation: item.valuation,
        changes: item.changes,
        slug: item.page.slug,
        created_at: item.page.created_at,
        title: item.page.title
      };
    });

    return data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
// export const GetAnalysisListUsecase: () => Promise<
//   AnalysisModel[]
// > = async () => {
//   try {
//     let response = await client.get(
//       ApiConst.API_ANALYSIS + "?status=published&limit=1000"
//     );
//     let formattedData: any = {};

//     response.data.data.data
//       .sort((a: any, b: any) =>
//         Moment.utc(b.page.created_at) < Moment.utc(a.page.created_at) ? -1 : 1
//       )
//       .forEach((item: any) => {
//         if (formattedData[item.code]) {
//           formattedData[item.code].pages.push({
//             created_at: item.page.created_at,
//             slug: item.page.slug,
//           });
//         } else {
//           formattedData[item.code] = {
//             id: item.id.toString(),
//             buy_price: item.buy_price,
//             code: item.code,
//             image_url: item.image_url,
//             margin_of_safety: item.margin_of_safety,
//             current_price: item.current_price,
//             valuation: item.valuation,
//             changes: item.changes,
//             pages: [
//               {
//                 created_at: item.page.created_at,
//                 slug: item.page.slug,
//               },
//             ],
//           };
//         }
//       });

//     return Object.entries(formattedData).map(
//       ([key, value]) => value
//     ) as AnalysisModel[];
//   } catch (e) {
//     throw new Error(e.response.data.message);
//   }
// };
