import { AnalysisModel } from "@Features/dashboard/data/analysis.model";
import { GetAnalysisListUsecase } from "@Features/dashboard/domain/GetAnalysisList.usecase";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Tabs } from "../components/Tabs.component";
import { StudyCaseSection } from "./AnalysisSubSection/StudyCase.component";
import { NoAccessSection } from "./NoAccessSection.component";

export const AnalysisSection = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState<AnalysisModel[]>([]);
  const [uiData, setUiData] = useState<AnalysisModel[]>([]);
  const [filterCode, setFilterCode] = useState("");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    async function fetchAnalysis() {
      setIsLoading(true);
      try {
        let response = await GetAnalysisListUsecase();
        setApiData(response);
        setUiData(response);
      } catch (e) { }
      setIsLoading(false);
    }
    fetchAnalysis();
  }, []);

  const filterStock = (code: string) => {
    if (code.length > 0) {
      let newUiData = [...apiData];
      newUiData = newUiData.filter((item) =>
        item.code.toLowerCase().includes(code.toLowerCase())
      );
      setUiData(newUiData);
    } else {
      clearFilter();
    }
  };

  const clearFilter = () => {
    setUiData(apiData);
    setFilterCode("");
  };

  // const buildViewBasedOnTab = () => {
  //   switch (selectedTabIndex) {
  //     case 0:
  //       return (
  //         <div className="text-sm -p-4">
  //           {uiData?.length !== 0 ? 
  //             <>
  //               <div>
  //                 <input
  //                   className="form-input w-full md:w-1/2"
  //                   type="text"
  //                   placeholder="Cari Saham"
  //                   onChange={(e) => {
  //                     filterStock(e.target.value);
  //                   }}
  //                   />
  //               </div>
  //               <StudyCaseSection 
  //                 data={uiData}
  //               />
  //             </>
  //             : <NoAccessSection/>
  //           }
  //         </div>
  //       );
  //     default:
  //       return <div className="text-center text-white text-xl"> Coming Soon </div>
  //   }
  // };

  return (
    <div className="mt-6">
      <Helmet encodeSpecialCharacters={true}>
        <title>Analisa Saham - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Analisa saham" />
      </Helmet>
      {isLoading && <MidasShimmering />}
      {!isLoading && (
        uiData?.length !== 0 && (filterCode.length === 0 || filterCode.length !== 0)  ? 
          <>
            <div>
              <input
                className="form-input w-full md:w-1/2"
                type="text"
                placeholder="Cari Saham"
                onChange={(e) => {
                  filterStock(e.target.value);
                  setFilterCode(e.target.value);
                }}
                />
            </div>
            <StudyCaseSection 
              data={uiData}
            />
          </>
          : filterCode.length > 0 ?
          <>
            <div>
              <input
                className="form-input w-full md:w-1/2"
                type="text"
                placeholder="Cari Saham"
                onChange={(e) => {
                  filterStock(e.target.value);
                  setFilterCode(e.target.value);
                }}
                />
            </div>
            <div className="py-10 text-center">
              <div className="text-white text-xl md:text-3xl lg:text-5xl font-bold">
                Data tidak tersedia.
              </div>
            </div>
          </>
          : <NoAccessSection/>
        
      //  <div>
      //     <Tabs
      //       tabItems={[
      //         "Study Cases",
      //         "Watchlist (NEW)"
      //       ]}
      //       selectedIndex={selectedTabIndex}
      //       onClickTab={(index) => {
      //         setSelectedTabIndex(index);
      //       }}
      //     />
      //     {buildViewBasedOnTab()}
      //   </div>
      )}
    </div>
  );
};
