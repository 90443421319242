import { FC } from "react";
import { Link } from "react-router-dom";
import { convertDateOnlyFromApiToView } from "@Libraries/utils/Formatter";


export type StudyCaseSectionProps = {
  data: any[]
};
export const StudyCaseSection: FC<StudyCaseSectionProps> = ({
  data,
}) => {
   return (
    <div>
      <div className="mt-4 grid gap-4 grid-cols-1 md:grid-cols-2 2xl:grid-cols-3">
        {data.map((item, index) => (
          <Link
            key={`analisa-${item.code}-${index}`}
            className="rounded-3xl w-full bg-black p-4 xs:p-2 sm:p-2 text-white relative"
            to={`/${item.slug}`}
          >
            <div className="flex my-auto">
              <div className="my-auto ">
                <img
                  src={item.image_url}
                  className="object-cover w-16 h-16 lg:w-24 lg:h-24 "
                  alt={`Analisa Saham ${item.code}`}
                />
              </div>
              <div className="my-auto ml-4 text-white font-normal text-sm sm:text-base xs:text-xs">
                <div className="mb-2 break-words color-brand-yellow text-2xl sm:text-3xl font-extrabold">
                  {item.code}
                </div>
                <div className="mb-1 text-xs text-left w-full pl-2">
                  {convertDateOnlyFromApiToView(item.created_at)}
                </div>
                <div className="break-words text-md text-left w-full pl-2">
                  {item.title.split(" ").slice(0, 4).join(" ") + "....."}
                </div>
                <div className="break-words text-md text-left w-full color-brand-yellow hover:color-yellow hover:text-black px-2 rounded">
                  {">> Baca Selengkapnya <<"}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

StudyCaseSection.defaultProps = {
  data: []
}