import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { UserSession } from "@Libraries/users/UserSession";

export const CheckoutUsecase: (
  cartId: string,
  email: string,
  password: string,
  name: string,
  phone: string,
  telegram: string,
  additionalInfo: any,
  affiliateName?: string
) => Promise<string> = async (
  cartId,
  email,
  password,
  name,
  phone,
  telegram,
  additionalInfo,
  affiliateName
) => {
    let formData = new FormData();

    if (!UserSession.checkIsLoggedIn()) {
      formData.append("email", email);
      formData.append("password", password);
      formData.append("name", name);
      formData.append("phone", phone);
      formData.append("telegram", telegram);
      formData.append("additional_info", JSON.stringify(additionalInfo));
    }

    if (affiliateName) {
      formData.append("affiliate_name", affiliateName);
    }

    let response = await client.post(
      ApiConst.API_CHECKOUT + "/" + cartId,
      formData
    );

    if (response.data.data.token) {
      UserSession.loginWithToken(response.data.data.token);
    }

    return response.data.data.checkout_url;
  };
