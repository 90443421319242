import { MdSpaceDashboard } from "react-icons/md";
import { FaMoneyBillWave } from "react-icons/fa";
import { BsFillGearFill } from "react-icons/bs";

export const AffiliateAreaMenu = [
  {
    label: "Settings",
    icon: <BsFillGearFill size={28} />,
    path: "/affiliate",
    lastPath: "affiliate",
    access: "member-area",
    isSpecialMenu: false,
  },
  {
    label: "Dashboard",
    icon: <MdSpaceDashboard size={28} />,
    path: "/affiliate/dashboard",
    lastPath: "dashboard",
    access: "member-area",
    isSpecialMenu: false,
  },
  {
    label: "Withdraw",
    icon: <FaMoneyBillWave size={28} />,
    path: "/affiliate/withdraw",
    lastPath: "withdraw",
    access: "member-area",
    isSpecialMenu: false,
  },
];
