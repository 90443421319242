/* eslint-disable react-hooks/exhaustive-deps */
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import { RecordModel } from "@Features/dashboard/data/record.model";
import { GetRecordListUsecase } from "@Features/dashboard/domain/GetRecordList.usecase";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import { Toaster } from "@Libraries/components/toast/Toast.component";
import {
  ToasterModel,
  ToasterPosition,
  ToasterType,
} from "@Libraries/components/toast/Toast.model";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export type RecordItemProps = {
  title: string;
  description: string;
  zoom_link: string;
  internalDestination?: string;
};

export const RecordItemListComponent: FC<RecordItemProps> = ({
  title,
  description,
  zoom_link,
  internalDestination,
}) => {
  const buildView = () => {
    return (
      <div className="w-full rounded-3xl bg-midas-neutral-900 text-white p-4">
        <div className="text-xl font-bold">{title}</div>
        {description !== "" && (
          <div className="mt-2 text-lg">{description}</div>
        )}
      </div>
    );
  };

  return internalDestination ? (
    <Link to={internalDestination} target={"_blank"}>
      {buildView()}
    </Link>
  ) : (
    <a href={zoom_link} target={"_blank"} rel="noreferrer">
      {buildView()}
    </a>
  );
};

export const RecordSection = () => {
  const [recordList, setRecordList] = useState<RecordModel[]>([]);

  const toasterContext = UseToasterContext();

  useEffect(() => {
    GetRecordListUsecase()
      .then((response) => {
        setRecordList(response);
      })
      .catch((errors) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Gagal memuat rekaman!",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      });
  }, []);

  return recordList.length === 0 ? (
    <MidasShimmering />
  ) : (
    <div className="mt-4">
      <Helmet encodeSpecialCharacters={true}>
        <title>Rekaman - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Rekaman" />
      </Helmet>
      <div className={`mt-4 grid grid-cols-1 gap-4`}>
        {recordList.map((item, index) => {
          return (
            <RecordItemListComponent
              key={`record-item-list-${index}`}
              {...item}
            />
          );
        })}
      </div>

      {toasterContext.toastList.length !== 0 && (
        <Toaster
          toastItems={toasterContext.toastList}
          isAutoDelete={true}
          position={ToasterPosition.BOTTOM_RIGHT}
          autoDeleteTimeInMilis={2000}
        />
      )}
    </div>
  );
};
