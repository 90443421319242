import { MidasLogo } from "@Assets/midas-logo";
import { UserSession } from "@Libraries/users/UserSession";
import {
  FaEnvelope,
  FaInstagram,
  FaMapMarkerAlt,
  FaWhatsapp,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { FooterSectionComponent } from "./FooterSection.component";

export const FooterComponent = () => {
  return (
    <div className="bg-midas-neutral-900 w-full flex flex-col py-12 px-4 lg:px-14">
      <div className="flex flex-col md:flex-row justify-center md:justify-between">
        <div className="my-auto">
          <MidasLogo className="" />
        </div>
        {UserSession.checkIsLoggedIn() &&
        UserSession.getUserSession().activeRoles.length > 0 ? (
          <div></div>
        ) : (
          <div className="flex text-right">
            <div className="text-xl font-normal text-white my-auto">
              Ready to get started?
            </div>
            <Link to={"/membership"}>
              <button className="color-yellow rounded-lg ml-5 font-bold text-lg py-4 px-6 my-auto">
                Get started
              </button>
            </Link>
          </div>
        )}
      </div>

      <div className="border-t my-8 border-white h-px"></div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="order-3 md:order-1">
          <FooterSectionComponent
            title="Hubungi kami"
            items={[
              {
                icon: <FaEnvelope size={16} />,
                label: "hello@midascuan.id",
                url: "",
                target: "_self",
              },
              {
                icon: <FaInstagram size={16} />,
                label: "@midascuan.id",
                url: "https://www.instagram.com/midascuan.id/",
                target: "_blank",
              },
              {
                icon: <FaWhatsapp size={16} />,
                label: "(+62) 878 8479 2402",
                url: "https://api.whatsapp.com/send?phone=6287884792402&text=Hai%20Midascuan.id.",
                target: "_blank",
              },
              {
                icon: <FaMapMarkerAlt size={16} />,
                label:
                  "MY REPUBLIC PLAZA, Wing A, Zona 6, Green Office Park, Jalan BSD Grand Boulevard, BSD City, Sampora, Cisauk, Kab. Tangerang, Banten. 15345",
                url: "",
                target: "_self",
              },
            ]}
          />
        </div>

        <div className="order-1 md:order-2">
          <FooterSectionComponent
            title="MIDAS CUAN ID"
            items={[
              {
                label: "MIDAS Stocks Membership",
                url: `${window.location.origin}/membership`,
                target: "_self",
              },
              {
                label: "Live Webinar",
                url: "https://lynk.id/midascuanid/zDVDkeg",
                target: "_blank",
              },
              {
                label: "Stocks Key Insight",
                url: `${window.location.origin}/stocks-key-insights`,
                target: "_self",
              },
              {
                label: "Free Ebook",
                url: `${window.location.origin}/ebookhartakarun`,
                target: "_self",
              },
            ]}
          />
        </div>

        <div className="order-2 md:order-3">
          <FooterSectionComponent
            title=""
            items={[
              {
                label: "Disclaimer",
                url: `${window.location.origin}/disclaimer`,
                target: "_self",
              },
              {
                label: "Kebijakan Privasi",
                url: `${window.location.origin}/privacy-policy`,
                target: "_self",
              },
              {
                label: "Syarat & Ketentuan",
                url: `${window.location.origin}/syarat-ketentuan`,
                target: "_self",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
